import { Disclosure } from "@headlessui/react";
import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { BriefcaseIcon, ArrowCircleDownIcon, DocumentTextIcon, TruckIcon, UserIcon, CalendarIcon, LocationMarkerIcon, MapIcon, SearchIcon, ClockIcon, UsersIcon, XIcon, ArrowCircleUpIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Underline from "../../conponents/Underline";
import { StaticImage } from "../../utils/image";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import ApiJob from "../../api/ApiJob";
import ApiImage from "../../api/ApiImage";
import CSwl from "../../utils/alert";
import { ConvertDate, Currency } from "../../utils";
import _, { sumBy } from 'lodash';
import Modal from "../../conponents/Modal";
import { CheckFile } from "../../utils";
import SignatureCanvas from 'react-signature-canvas'
import Barcode from "react-barcode/lib/react-barcode";
import ApiUser from "../../api/ApiUser";
import { TrashIcon } from "@heroicons/react/outline";
import Select from 'react-select'

const Container = styled.div``;

const Job = () => {
    const [suggestModal, setsuggestModal] = useState(false);
    const [reasonSuggest, setreasonSuggest] = useState('');
    const [isChangeSuggestFirst, setisChangeSuggessFirst] = useState(false);
    const [isChangeSuggest, setIsChangeSuggest] = useState(false);
    const [shiptoSelected, setShiptoSelected] = useState(false);
    const [jobDetail, setJobDetail] = useState({
        dsHeaders_Job: [],
        reasons: []
    });
    const [dpNumber, setdpNumber] = useState('');
    const [isModal, setisModal] = useState(false);
    const [barCode, setbarCode] = useState({
        shipmentNoImg: "",
        saleOrgImg: "",
        shipmentNo: "",
        orgNo: ""
    });
    const navigate = useNavigate();
    const getJob = async () => {
        setJobDetail({
            dsHeaders_Job: [],
            reasons: []
        })
        CSwl.SwalLoad();

        var url_string = window.location;
        var url = new URL(url_string);
        var q = url.searchParams.get("type");

        var pageState = 1;
        var dataType = "Job";

        if (q == "pickup") {
            pageState = 3;
        }
        else if (q == "delivery") {
            pageState = 4;
        }
        else if (q == "complete") {
            pageState = 6;
            dataType = "Complete";
        }

        const response = await ApiJob.getJob({ type: dataType, state: pageState });
        if (response.status == 200) {

            if (response.data.current_Job == 0 && pageState == 4) {
                navigate('/jobTimeline');
            }

            setJobDetail(response.data);
        }
        CSwl.SwalClose();
        //#endregion

    }
    const [state, setState] = useState({
        pageType: ""
    });
    useEffect(() => {
        var url_string = window.location;
        var url = new URL(url_string);
        var q = url.searchParams.get("type");
        setState({
            pageType: q
        })
        getJob();
    }, []);
    const userState = useSelector((state) => state.user);
    const [data, setdata] = useState([
        {
            no: '1001 บจก ปปป', dp: [
                { no: '428432755', dn: [{ no: "428432755" }] },
                { no: '428432756', dn: [{ no: "428432756" }] },
                { no: '428432757', dn: [{ no: "428432757" }] }
            ]
        },
        {
            no: '1002 บจก ยยยย', dp: [
                { no: '428432755', dn: ["428432755-1", "428432755-2"] },
                { no: '428432756', dn: ["428432756-1", "428432756-2"] },
                { no: '428432757', dn: ["428432757-1", "428432757-2"] }
            ]
        },
    ]);

    const renderDP = (item) => {
        let components = [];
        const groupItems = _(item.dpDetails)
            .groupBy('dpno')
            .map((value, key) => ({ dpno: key, dnDetail: value }))
            .value();
        groupItems.map((subitem, subindex) => {
            components.push(<div className="mt-2">
                <JobDetailTab1 dsDetail={item} userState={userState} pageType={state.pageType} item={subitem} index={subindex} refreshData={getJob} />
            </div>)
        })
        return components;
    }

    const genBarcode = async (item) => {
        setbarCode({
            shipmentNo: item.shipmentno,
            orgNo: item.salesOrg
        })
        setisModal(true);
        //#region getjob
        // CSwl.SwalLoad();
        // const response = await ApiJob.getBarcode({ SaleOrg: item.salesOrg, ShipmentNo: item.shipmentno });
        // if (response.status == 200) {
        //     setbarCode({
        //         saleOrgImg: response.data.saleOrgImg,
        //         shipmentNoImg: response.data.shipmentNoImg
        //     })
        //     setisModal(true);
        // }
        // CSwl.SwalClose();
        //#endregion
    }

    const changeComment = (e, dsDetail) => {
        const headerJob = [...jobDetail.dsHeaders_Job];
        const headerIndex = headerJob.findIndex(x => x.shipmentno == dsDetail.shipmentno);
        if (headerIndex > -1) {
            const dsDetailIndex = headerJob[headerIndex].dsDetails.findIndex(x => x.shipto == dsDetail.shipto);
            if (dsDetailIndex > -1) {
                if (e.length == 0) {
                    headerJob[headerIndex].dsDetails[dsDetailIndex].comments = "";
                } else {
                    headerJob[headerIndex].dsDetails[dsDetailIndex].comments = e.map(x => x.value).join(",")
                }
            }
        }
        setJobDetail({
            ...jobDetail,
            dsHeaders_Job: headerJob
        })
    }


    const onSearchDP = async () => {
        try {
            CSwl.SwalLoad();
            const result = await ApiJob.getDataEDP({
                doc_no: dpNumber
            })
            CSwl.SwalClose();
            if (result.status == 200) {
                getJob();
            } else {
                var message = JSON.parse(result.data.message);
                CSwl.SwalErr(`${message.Message}`);
            }
        } catch (error) {
            CSwl.SwalErr(`${error.data}`);
            CSwl.SwalClose();
        }

    }

    const deleteShipment = async (shipmentno) => {
        const confirmDelete = await CSwl.SwalConfirm("คุณต้องการลบใบงานนี้")
        if (confirmDelete.isConfirmed) {
            try {
                CSwl.SwalLoad();
                const result = await ApiJob.deleteShipment({
                    shipmentNo: shipmentno
                })
                CSwl.SwalSuccess('', { html: 'สำเร็จ' }).then(x => {
                    navigate('/jobTimeline');
                });

            } catch (error) {
                CSwl.SwalErr(`${error.data}`);
                CSwl.SwalClose();
            }
        }
    }

    const saveOrderSuggest = async () => {

        if (isChangeSuggestFirst) {
            setsuggestModal(true)
        } else {
            setIsChangeSuggest(false)
        }

        // setShiptoSelected("")
    }

    const saveOrderSuggestModal = async () => {
        if (reasonSuggest) {
            CSwl.SwalLoad();
            const headerJob = [...jobDetail.dsHeaders_Job];
            var shipmentJob = headerJob.find(x => x.shipmentno == shiptoSelected.shipmentno);
            const dsDetail = _.orderBy(shipmentJob.dsDetails, 'suggestOrderId');
            const tranWH = dsDetail.map(x => {
                return { dpno: x.dpno, suggestOrderId: x.suggestOrderId }
            });
            const response = await ApiJob.saveSuggestOrder({
                shipmentNo: shiptoSelected.shipmentno,
                reason: reasonSuggest,
                tranLtmswhs: tranWH
            });
            if (response.status == 200) {
                await CSwl.SwalSuccess('', { html: 'สำเร็จ' });
                getJob();
            }
            else {
                CSwl.SwalErr(`${response.data}`);
            }
            setisChangeSuggessFirst(false)
            setsuggestModal(false)
            setIsChangeSuggest(false)
        } else {
            CSwl.SwalErr("กรุณาเลือกสาเหตุการเปลี่ยนที่ขึ้นสินค้า")
        }

    }

    const changeSuggest = (suggestIndex, value) => {
        const newJobArray = [...shiptoSelected.dsDetails];
        const dsDetail = _.orderBy(newJobArray, 'suggestOrderId');
        if (value == -1) {
            dsDetail[suggestIndex - 1].suggestOrderId += Math.abs(value);
        } else {
            dsDetail[suggestIndex + 1].suggestOrderId -= value;
        }
        dsDetail[suggestIndex].suggestOrderId += value;
        shiptoSelected.dsDetails = newJobArray;

        const headerJob = [...jobDetail.dsHeaders_Job];
        const headerIndex = headerJob.findIndex(x => x.shipmentno == dsDetail.shipmentno);
        if (headerIndex > -1) {
            headerJob[headerIndex].dsDetails = newJobArray
        }
        setJobDetail({
            ...jobDetail,
            dsHeaders_Job: headerJob
        })
        setisChangeSuggessFirst(true)
        setShiptoSelected(shiptoSelected)
    }

    return <div className="pb-5">
        <nav class="flex bg-white text-gray border-b border-gray-200 py-3 px-5  mb-4" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center">
                    <Link to="/" className="text-gray-700 hover:text-gray-900 inline-flex items-center">
                        <svg class="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                        หน้าแรก
                    </Link>
                </li>
                <li class="inline-flex items-center">
                    <Link to="/jobTimeline" className="text-gray-700 hover:text-gray-900 inline-flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        ไทม์ไลน์
                    </Link>
                </li>
                <li aria-current="page">
                    <div class="flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <span class="text-red  ml-1 md:ml-2 font-medium">{state.pageType == "pickup" ? "เดินทางมาขึ้นสินค้า" : state.pageType == "delivery" ? "ขับรถไปส่งสินค้า" : "ประวัติงาน"}</span>
                    </div>
                </li>
            </ol>
        </nav>
        {
            (state.pageType != "complete" && userState.edpRoleId == 5) && <div className="flex px-3 pb-5">
                <div className="flex-1 mr-2">
                    <input value={dpNumber} onChange={(e) => {
                        setdpNumber(e.target.value)
                    }} className="form-control" placeholder="DP No. or Shipment No."></input>
                </div>
                {/* <div className="flex mr-2">
                    <input className="form-control" placeholder="ทะเบียนรถ"></input>
                </div> */}
                <button onClick={() => {
                    onSearchDP();
                }} className="px-3 bg-blue-600 rounded">
                    <SearchIcon className="w-4 h-4 text-white" />
                </button>
            </div>
        }

        {

            jobDetail.dsHeaders_Job.map((job, Jobindex) => {
                const shipTo = [];
                return (
                    <>
                        <Disclosure defaultOpen={state.pageType != "complete"}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className={`flex w-full justify-between
        ${open ? 'rounded-bl-none rounded-br-none ' : ''}
        bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75
        ${state.pageType != "complete" ? 'hidden' : ''}
        `
                                    }
                                    >
                                        <span>ใบสั่งบรรทุกเลขที่: {job.shipmentno}</span>
                                        <ChevronDownIcon
                                            className={`${open ? 'rotate-180 transform' : ''
                                                } h-5 w-5 text-purple-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-2 pt-1 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                                        <div className="px-3">
                                            <div className="flex justify-between items-center">
                                                <div className="flex">
                                                    <BriefcaseIcon
                                                        className={`h-5 w-5 mr-1`}
                                                    />
                                                    <span style={{ color: 'blue' }} className="text-info text-bold">{job.contractName}</span>
                                                </div>
                                                {
                                                    (state.pageType == "delivery" && userState.edpRoleId == 5) &&
                                                    <div onClick={() => deleteShipment(job.shipmentno)} className="flex">
                                                        <XIcon
                                                            className={`h-6 w-6 text-[#dc2626]`}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex">
                                                <DocumentTextIcon
                                                    className={`h-5 w-5 mr-1`}
                                                />
                                                <span className="text-bold">ใบสั่งบรรทุกเลขที่: {job.shipmentno}</span>
                                            </div>

                                            <div onClick={() => genBarcode(job)} className="flex justify-end cursor-pointer">
                                                <span className="btn-link">แสดงบาร์โค๊ดใบสั่งบรรทุก</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="flex items-center">
                                                    <TruckIcon
                                                        className={`h-5 w-5 mr-1`}
                                                    />
                                                    <span>{job.truckid}</span>
                                                </div>
                                                <div className="flex items-center">
                                                    <TruckIcon
                                                        className={`h-5 w-5 mr-1`}
                                                    />
                                                    <span>{job.trucktype}</span>
                                                </div>
                                            </div>


                                            <div className="flex">
                                                <UserIcon
                                                    className={`h-5 w-5 mr-1`}
                                                />
                                                <span>{jobDetail.userProfile.drivername}</span>
                                            </div>
                                            <div className="h-1 mt-2 mb-2 border-b border-gray-200" />
                                            <div className="flex">
                                                <span className="text-bold w-16">จ่ายงาน</span>
                                                <div className="flex">
                                                    <CalendarIcon
                                                        className={`h-5 w-5 mr-1`}
                                                    />
                                                    <span>{ConvertDate(job.shipmentdate, "ddd DD MMM yyyy HH:mm:ss")}</span>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <span className="text-bold w-16">กำหนดส่ง</span>
                                                <div className="flex">
                                                    <CalendarIcon
                                                        className={`h-5 w-5 mr-1`}
                                                    />
                                                    <span>{job.transportdate ? ConvertDate(job.transportdate, "ddd DD MMM yyyy HH:mm:ss") : ""}</span>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <span className="text-bold w-16">หมายเหตุ</span>
                                                <div className="flex">
                                                    <span className="text-red">{job.remark}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-2 justify-end text-right">
                                                    {
                                                        !isChangeSuggest && state.pageType == "pickup" && <span onClick={() => {
                                                            setShiptoSelected(job)
                                                            setIsChangeSuggest(true)
                                                        }} className="cursor-pointer btn-link">เปลี่ยนลำดับการขึ้น</span>
                                                    }
                                                    {
                                                        isChangeSuggest && state.pageType == "pickup" && <span onClick={() => {
                                                            saveOrderSuggest(job);
                                                        }} className="cursor-pointer btn-link">บันทึกข้อมูล</span>
                                                    }

                                                </div>

                                            </div>
                                            {/* <div className="h-1 mt-2 mb-2 border-b border-gray-200" /> */}
                                        </div>
                                        {/* <div className="px-3 mt-4">
            {data.map((item, index) => {
                return <JobDetail item={item} index={index} />
            })}
        </div> */}
                                        <div className="mt-2">
                                            {
                                                job.dsDetails.filter((item, index) => {
                                                    if (!shipTo.find((s) => s.id == item.shipto)) {
                                                        shipTo.push({ name: item.shiptoname, id: item.shipto });
                                                    }
                                                    return null;
                                                })
                                            }
                                            {
                                                shipTo.map((s, i) => {
                                                    return <ShipToRender job={job} sa s={s} i={i} pageType={state.pageType} getJob={getJob} />
                                                })
                                            }
                                            {state.pageType == "pickup" &&
                                                <div className="">
                                                    <JobDetailTab2 shipmentNo={job.shipmentno} photos={job.photos} />
                                                </div>
                                            }

                                        </div>

                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>

                    </>
                )
            })
        }

        <Modal modalClass="w-full" isModal={isChangeSuggest} onClose={() => {
            setIsChangeSuggest(false)
            setShiptoSelected("")
            setisChangeSuggessFirst(false)
        }}
            onSubmit={() => {
                saveOrderSuggest();
            }}
        >
            <div className="flex w-full items-center flex-col">
                <div className="mt-2 flex flex-col w-full">
                    {
                        _.orderBy(shiptoSelected.dsDetails, 'suggestOrderId').map((item, index) => {
                            return <JobDetailDpModal
                                key={item.suggestOrderId}
                                isChangeSuggest={true}
                                orderSuggest={index + 1}
                                changeSuggest={changeSuggest}
                                lengthDP={shiptoSelected.dsDetails.length}
                                item={item} />
                        })
                    }
                    {/* {
                        shiptoSelected.dsDetails.filter((item, index) => {
                            if (!shipTo.find((s) => s.id == item.shipto)) {
                                shipTo.push({ name: item.shiptoname, id: item.shipto });
                            }
                            return null;
                        })
                    }
                    {
                        shipTo.map((s, i) => {
                            return <ShipToRender job={job} sa s={s} i={i} pageType={state.pageType} getJob={getJob} />
                        })
                    } */}
                </div>
            </div>
        </Modal>

        <Modal isModal={isModal} onClose={() => setisModal(false)}>
            <div className="flex items-center flex-col">
                <div className="flex items-center flex-col gap-10">
                    <Barcode width={3} value={barCode.shipmentNo} />
                    <Barcode width={3} value={barCode.orgNo} />
                </div>
                {/* <img
                    className=""
                    src={`data:image/png;base64, ${barCode.shipmentNoImg}`} />
                <img
                    className=""
                    src={`data:image/png;base64, ${barCode.saleOrgImg}`} /> */}

            </div>
        </Modal>
        <Modal isModal={suggestModal} onSubmit={() => {
            saveOrderSuggestModal();
        }} onClose={() => setsuggestModal(false)}>
            <div className="w-60 flex items-center flex-col gap-10">
                <label>สาเหตุการเปลี่ยนที่ขึ้นสินค้า</label>
                <select onChange={(e) => setreasonSuggest(e.target.value)} className="border-b border-black mb-1">
                    <option value="">สาเหตุการเปลี่ยนที่ขึ้นสินค้า</option>
                    {
                        jobDetail && jobDetail.reasons.map((item, index) => {
                            return (
                                <option value={item.valueCode}>{item.valueText}</option>
                            )
                        })
                    }
                </select>
            </div>
        </Modal>
    </div>
}

const ShipToRender = ({ s, i, pageType, job, getJob }) => {
    const userState = useSelector((state) => state.user);
    const [newJob, setnewJob] = useState(job);
    const renderDP = (item) => {
        let components = [];
        const groupItems = _(item.dpDetails)
            .groupBy('dpno')
            .map((value, key) => ({ dpno: key, dnDetail: value }))
            .value();
        groupItems.map((subitem, subindex) => {
            components.push(<div className="mt-2">
                <JobDetailTab1 dsDetail={item} userState={userState} pageType={pageType} item={subitem} index={subindex} refreshData={getJob} />
            </div>)
        })
        return components;
    }


    const changeSuggest = (suggestIndex, value) => {
        const newJobArray = [...newJob.dsDetails];
        const dsDetail = _.orderBy(newJobArray, 'suggestOrderId').filter((f) => {
            return f.shiptoname === s.name;
        })
        if (value == -1) {
            dsDetail[suggestIndex - 1].suggestOrderId += Math.abs(value);
        } else {
            dsDetail[suggestIndex + 1].suggestOrderId -= value;
        }
        dsDetail[suggestIndex].suggestOrderId += value;

        setnewJob({
            ...newJob,
            dsDetails: dsDetail
        })
    }

    const saveOrderSuggest = async () => {
        CSwl.SwalLoad();
        const dsDetail = _.orderBy(newJob.dsDetails, 'suggestOrderId').filter((f) => {
            return f.shiptoname === s.name;
        })
        const tranWH = dsDetail.map(x => {
            return { dpno: x.dpno, suggestOrderId: x.suggestOrderId }
        });
        const response = await ApiJob.saveSuggestOrder(tranWH);
        if (response.status == 200) {
            await CSwl.SwalSuccess('', { html: 'สำเร็จ' });
            getJob();
        }
        else {
            CSwl.SwalErr(`${response.data}`);
        }
        // setIsChangeSuggest(false)
    }

    return <>
        <Disclosure defaultOpen key={i}>
            {({ open }) => (
                <>
                    <div className={`flex flex-col w-full justify-between
                                ${open ? 'rounded-bl-none rounded-br-none ' : ''}
                                bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75
                                ${pageType == "pickup" ? '' : ''}
                                                                `
                    }>
                        <Disclosure.Button
                            className={'flex-1 flex justify-between items-center'}
                        >
                            <span className="text-left">ShipTo {s.name} <br />
                                <span className="text-md">{job.dsDetails.find((f) => f.shiptoname == s.name).shiptoaddress}</span>
                            </span>
                            <ChevronDownIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-purple-500`}
                            />

                        </Disclosure.Button>
                        {/* {
                            open && <div className="mt-2 justify-end text-right">
                                {
                                    !isChangeSuggest && pageType == "pickup" && <span onClick={() => {
                                        setIsChangeSuggest(true)
                                    }} className="cursor-pointer">เปลี่ยนลำดับการขึ้น</span>
                                }
                                {
                                    isChangeSuggest && pageType == "pickup" && <span onClick={() => {
                                        saveOrderSuggest();
                                    }} className="cursor-pointer">บันทึกข้อมูล</span>
                                }

                            </div>
                        } */}

                    </div>



                    {
                        _.orderBy(newJob.dsDetails, 'suggestOrderId').filter((f) => {
                            return f.shiptoname === s.name;
                        }).map((item, index) => {
                            return (
                                <Disclosure.Panel className="px-2 pt-1 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                                    <JobDetailDp
                                        pageType={item.whStatus == "Complete" ? "complete" : pageType}
                                        //isChangeSuggest={isChangeSuggest}
                                        orderSuggest={index + 1}
                                        changeSuggest={changeSuggest}
                                        lengthDP={job.dsDetails.filter((f) => f.shiptoname == s.name).length}
                                        item={item} />
                                    {
                                        <>
                                            <Disclosure defaultOpen key={i}>
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className={`flex w-full justify-between ${open ? 'rounded-bl-none rounded-br-none ' : ''} bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                                                            <span>แสดงรายละเอียด</span>
                                                            <ChevronDownIcon
                                                                className={`${open ? 'rotate-180 transform' : ''
                                                                    } h-5 w-5 text-purple-500`}
                                                            />
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className="px-2 pt-1 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                                                            {renderDP(item)}
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>

                                        </>
                                    }
                                    {((pageType == "delivery" || pageType == "complete") && userState.edpRoleId != 5) &&
                                        <div className="">
                                            <JobDetailTab2Detail pageType={pageType} photos={newJob.photos} />
                                        </div>
                                    }
                                    {
                                        (pageType == "delivery" || pageType == "complete") && <JobDeliveryInformation
                                            header={newJob}
                                            dpItem={item}
                                            dpIndex={index + 1}
                                            lengthDP={job.dsDetails.filter((f) => f.shiptoname == s.name).length}
                                            dsDetails={newJob.dsDetails.find((f) => {
                                                return f.shiptoname === s.name;
                                            })} 
                                            shiptoid={s.id} 
                                            shipto={s.name} 
                                            shipmentNo={newJob.shipmentno} 
                                            photos={newJob.sign}
                                            pageType={newJob.dsDetails.find((f) => {
                                                return f.shiptoname === s.name;
                                            }).whStatus == "Complete" ? "complete" : pageType}
                                            refreshData={getJob} />
                                    }
                                </Disclosure.Panel>
                            )
                        })
                    }





                </>
            )}
        </Disclosure>
        <div style={{ height: 1 }} className=" border-white" />
    </>
}

const JobDetailTab1 = ({ dsDetail, item, pageType, index, refreshData }) => {
    return <DNContainer dsDetail={dsDetail} pageType={pageType} subIndex={index} subItem={item} refreshData={refreshData} />
}

const DNContainer = ({ dsDetail, subItem, subIndex, pageType, refreshData, getJob }) => {
    const userState = useSelector((state) => state.user);
    const [isModal, setisModal] = useState(false);
    const [isFinishWHModal, setFinishWHModal] = useState(false);
    const [isOtherFinishWHRemark, setOtherFinishWHRemark] = useState(false);
    const navigate = useNavigate();
    const [remark, setremark] = useState({
        remark: "",
        remarkOther: ""
    });
    const finishWH = async (shipmentNo, remark, dpno) => {
        CSwl.SwalLoad();
        const response = await ApiJob.finishWH(shipmentNo, remark, dpno);
        if (response.status == 200) {
            CSwl.SwalClose();
            refreshData();
            setFinishWHModal(false);
            setOtherFinishWHRemark(false);
            setremark({
                remark: "",
                remarkOther: ""
            });
        }
        else {
            CSwl.SwalErr(`${response.data}`);
        }

    }

    useEffect(() => {
    }, []);


    return <>
        <Modal isModal={isModal} onClose={() => setisModal(false)}>
            <div className="flex items-center flex-col">
                <div className="flex items-center flex-col gap-10">
                    <Barcode width={3} value={dsDetail.dpno} />
                </div>
                {/* <img
                    className=""
                    src={`data:image/png;base64, ${barCode.shipmentNoImg}`} />
                <img
                    className=""
                    src={`data:image/png;base64, ${barCode.saleOrgImg}`} /> */}

            </div>
        </Modal>
        <div className="px-3 py-3 border border-gray-200">
            {/* <div className="flex justify-between">

                <div className="flex">
                    <LocationMarkerIcon
                        className={`h-5 w-5 mr-1`}
                    />
                    <span>{dsDetail.whaddress}</span>
                </div>
                <div className="flex">
                    <span onClick={() => {
                        setisModal(true);
                    }} className="btn-link">Barcode DP</span>
                </div>
            </div> */}
            {/* <div className="justify-between flex row text-row">
                <div className="flex">
                    <span className="left whitespace-nowrap">เลขที่ใบตั้ง: </span>
                    <span className="text-gray right">&nbsp;{dsDetail.dpno}</span>
                </div>
                {
                    userState.edpRoleId != 5 && <div>
                        <span className="left">สถานะ: </span>

                        <span className="text-gray right">&nbsp;{userState.edpRoleId != 5 ? dsDetail.currentStatus : "-"} <span className="text-red-500 text-base">({ConvertDate(dsDetail.createDate)})</span></span>
                    </div>
                }

            </div> */}
            {/* <div className="justify-between flex row mt-2 text-row">
                <div className="flex flex-col">
                </div>
                {
                    userState.edpRoleId != 5 && <div className="flex flex-col">
                        <span className="text-base text-green-300 text-green-700">{userState.edpRoleId != 5 ? dsDetail.dpstatus : "ขับรถไปส่งสินค้า"}</span>
                    </div>
                }

            </div> */}
            {/* {
                userState.edpRoleId != 5 && <div className="text-row">
                    <span className="left">ช่องจอดที่: </span>
                    <span className="text-gray" dangerouslySetInnerHTML={{ __html: dsDetail.parkLotNames }}></span>
                </div>
            }



            <div className="justify-between flex row text-row">
                <div>
                    <span className="left">จำนวน({(dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0) && dsDetail.dpDetails[0].batchItems[0].unit}): </span>
                    <span className="text-gray right">{(dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0 ? dsDetail.tranMain.sumQtyAmount : dsDetail.qty)}</span>
                </div>
                <div>
                    <span className="left">น้ำหนัก({(dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0) && dsDetail.dpDetails[0].batchItems[0].unitton}): </span>
                    <span className="text-gray right">&nbsp;{(dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0) && dsDetail.dpDetails[0].batchItems[0].unitton == "KG" ? dsDetail.weight * 1000 : dsDetail.weight} </span>
                </div>
            </div> */}
            <>
                <table class="table-auto border-collapse border border-slate-500 w-full">
                    <thead>
                        <tr>
                            <th className="text-center" style={{ width: '27px' }}>No</th>
                            <th className="">Material</th>
                            {
                                pageType == "pickup" && <>
                                    <th className="text-left" style={{ width: '70px' }}>
                                        {
                                            (dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].qtyUnit == "TON" ? "ROL" : dsDetail.dpDetails[0].qtyUnit)
                                        }
                                    </th>
                                    <th className="text-left" style={{ width: '70px' }}>
                                        {
                                            (dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].weightUnit == "KG" ? "TON" : dsDetail.dpDetails[0].weightUnit)
                                        }
                                    </th>

                                </>
                            }
                            {
                                pageType == "delivery" && <>
                                    <th className="text-left" style={{ width: '70px' }}>
                                        {
                                            ((dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0) ? dsDetail.dpDetails[0].batchItems[0].unit : dsDetail.dpDetails[0].qtyUnit) == "TON" ? "ROL" :
                                                ((dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0) ? dsDetail.dpDetails[0].batchItems[0].unit : dsDetail.dpDetails[0].qtyUnit)
                                        }
                                    </th>
                                    <th className="text-left" style={{ width: '70px' }}>{(dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0)
                                        ? dsDetail.dpDetails[0].batchItems[0].unitton == "KG" ? "TON" : dsDetail.dpDetails[0].batchItems[0].unitton
                                        : dsDetail.dpDetails[0].weightUnit == "KG" ? "TON" : dsDetail.dpDetails[0].weightUnit}</th>

                                </>
                            }

                        </tr>
                    </thead>
                    <tbody className="pb-2">
                        {
                            subItem.dnDetail.map((itemMaterial, indexMaterial) => {
                                return <BatchItemRender

                                    unitton={(dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0) ? dsDetail.dpDetails[0].batchItems[0].unitton : dsDetail.dpDetails[0].weightUnit}
                                    unit={(dsDetail.dpDetails.length > 0 && dsDetail.dpDetails[0].batchItems.length > 0) ? dsDetail.dpDetails[0].batchItems[0].unit : dsDetail.dpDetails[0].qtyUnit}
                                    getJob={getJob} dsDetail={dsDetail} pageType={pageType} itemMaterial={itemMaterial} indexMaterial={indexMaterial} refreshData={refreshData} />
                            })
                        }
                    </tbody>
                </table>

            </>
            {/* {subItem.no == "428432755" ? <>
            <table class="table-auto border-collapse border border-slate-500 w-full">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '27px' }}>No</th>
                        <th className="">Material</th>
                        <th className="text-left" style={{ width: '70px' }}>ROLl</th>
                        <th className="text-left" style={{ width: '70px' }}>Ton</th>
                    </tr>
                </thead>
                <tbody className="pb-2">
                    <tr>
                        <td className="text-center">1</td>
                        {pageType == "delivery" ? <td onClick={() => setactive(!active)}>
                            <div className="flex btn-link items-center justify-between">
                                <span>Z02EK-080</span>
                                <ChevronDownIcon
                                    className={`${active ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-purple-500`}
                                />
                            </div>
                        </td> :
                            <td>
                                <div className="flex btn-link items-center justify-between">
                                    <span>Z02EK-080</span>
                                </div>
                            </td>
                        }
                        <td>8.000</td>
                        <td>5.430</td>
                    </tr>
                </tbody>
            </table>
            {active &&
                <table class="table-auto border-collapse border border-slate-500 w-full">
                    <thead>
                        <tr>
                            <th className="" style={{ width: '27px' }}></th>
                            <th className="">Batch</th>
                            <th className="text-left" style={{ width: '70px' }}>นน สินค้า</th>
                            <th className="text-left" style={{ width: '70px' }}>นน รับ</th>
                        </tr>
                    </thead>
                    <tbody className="pb-2">
                        <tr>
                            <td></td>
                            <td className="px-2">A0121425A</td>
                            <td>1.1396</td>
                            <td><input defaultValue={1.1396} step={0.001} type={"number"} className="border-b border-black mb-1" style={{ width: '50px' }} /></td>
                        </tr>
                        <tr className="mt-1">
                            <td></td>
                            <td className="px-2">เหตุผล</td>
                            <td colSpan={2}>
                                <select className="border-b border-black mb-1" style={{ width: '120px' }}>
                                    <option value="เปียกน้ำ">เปียกน้ำ</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="px-2">A0121425B</td>
                            <td>1.1396</td>
                            <td><input defaultValue={1.1396} step={0.001} type={"number"} className="border-b border-black mb-1" style={{ width: '50px' }} /></td>
                        </tr>
                        <tr className="mt-1">
                            <td></td>
                            <td className="px-2">เหตุผล</td>
                            <td colSpan={2}>
                                <select className="border-b border-black mb-1" style={{ width: '120px' }}>
                                    <option value="เปียกน้ำ">เปียกน้ำ</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </> : <>
            <table class="table-auto border-collapse border border-slate-500 w-full">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '27px' }}>No</th>
                        <th className="">Material</th>
                        <th className="text-left" style={{ width: '70px' }}>Ream</th>
                        <th className="text-left" style={{ width: '70px' }}>Ton</th>
                    </tr>
                </thead>
                <tbody className="pb-2">
                    <tr>
                        <td className="text-center">1</td>
                        {pageType == "delivery" ? <td onClick={() => setactive(!active)}>
                            <div className="flex btn-link items-center justify-between">
                                <span>Z02EK-080</span>
                                <ChevronDownIcon
                                    className={`${active ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-purple-500`}
                                />
                            </div>
                        </td> :
                            <td>
                                <div className="flex btn-link items-center justify-between">
                                    <span>Z02EK-080</span>
                                </div>
                            </td>
                        }
                        <td>50</td>
                        <td>1.1396</td>
                    </tr>
                </tbody>
            </table>
            {active &&
                <table class="table-auto border-collapse border border-slate-500 w-full">
                    <thead>
                        <tr>
                            <th className="" style={{ width: '27px' }}></th>
                            <th className="">Batch</th>
                            <th className="text-left" style={{ width: '70px' }}>จำนวน</th>
                            <th className="text-left" style={{ width: '70px' }}>นน สินค้า</th>
                        </tr>
                    </thead>
                    <tbody className="pb-2">
                        <tr>
                            <td></td>
                            <td className="px-2">A0121425A</td>
                            <td>50</td>
                            <td>1.1396</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><input defaultValue={50} step={1} type={"number"} className="border-b border-black mb-1" style={{ width: '50px' }} /></td>
                            <td><input defaultValue={1.1396} step={0.001} type={"number"} className="border-b border-black mb-1" style={{ width: '50px' }} /></td>
                        </tr>
                        <tr className="mt-1">
                            <td></td>
                            <td className="px-2">เหตุผล</td>
                            <td colSpan={2}>
                                <select className="border-b border-black mb-1" style={{ width: '120px' }}>
                                    <option value="เปียกน้ำ">เปียกน้ำ</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
        } */}

            {
                pageType == "pickup" && <div className="flex justify-end mt-2">
                    <button className="btn-blue ml-2" hidden={dsDetail.whStatus != "Accept"}
                        onClick={() => { dsDetail.isSuggestOrder ? finishWH(dsDetail.shipmentno, '', dsDetail.dpno) : setFinishWHModal(true) }}
                    >ปิดงาน</button>
                </div>
            }


            <Modal isModal={isFinishWHModal} onClose={() => setFinishWHModal(false)}
                onSubmit={() => {
                    finishWH(dsDetail.shipmentno,
                        remark.remark == "OTH" ? remark.remarkOther : remark.remark,
                        dsDetail.dpno)
                }}>
                <div className="w-80 flex items-center flex-col gap-10">
                    <select className="border-b border-black mb-1" onChange={(e) => {
                        setremark({
                            remark: e.target.value,
                            remarkOther: e.target.value != "OTH" ? "" : remark.remarkOther
                        })
                        e.target.value == "OTH" ? setOtherFinishWHRemark(true) : setOtherFinishWHRemark(false)
                    }}>
                        <option selected>โปรดเลือก</option>
                        <option> ฐานอยู่วังศาลา </option>
                        <option> ฐานอยู่บ้านโป่ง </option>
                        <option> ไม่ต้องการรื้อสินค้า </option>
                        <option> ตั้งสินค้าไม่เสร็จ </option>
                        <option value="OTH"> อื่น ๆ </option>
                    </select>
                    <input onChange={(e) => {
                        setremark({
                            ...remark,
                            remarkOther: e.target.value,
                        })
                    }} className={`border-b border-black mb-1 ${isOtherFinishWHRemark ? "" : "hidden"}`} />
                </div>
            </Modal>
        </div >
    </>
}

const BatchItemRender = ({ dsDetail, unit, unitton, itemMaterial, indexMaterial, pageType, refreshData }) => {
    const commentMat = useSelector((state) => state.commentMat);
    const [active, setactive] = useState(false);
    const [batchItems, setbatchItems] = useState([]);
    const [isChangeBatch, setisChangeBatch] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setbatchItems(itemMaterial.batchItems);
        console.log('uniton', unitton)
    }, [itemMaterial.batchItems]);


    useEffect(() => {
        dispatch({ type: "set", batchItems: batchItems });
    }, [batchItems]);

    useEffect(() => {
        dispatch({ type: "set", isChangeBatch: isChangeBatch });
    }, [isChangeBatch]);

    const saveBatchItem = async () => {
        const batchItemsChange = batchItems.filter(item => (item.weightConfirm && item.weight != item.weightConfirm) || (item.qtyConfirm && item.qty != item.qtyConfirm))
        if (batchItemsChange.filter(x => x.comment == null).length > 0) {
            CSwl.SwalErr(`กรุณาระบุเหตุผล`);
        } else {
            const response = await ApiJob.saveBatchItem(batchItems);
            if (response.status == 200) {
                setisChangeBatch(false)
                CSwl.SwalSuccess('', { html: 'สำเร็จ' });

            }
            else {
                CSwl.SwalErr(`${response.data}`);
            }
        }

    }

    const refreshBatchItem = async () => {
        console.log("batchItems::", batchItems)
        CSwl.SwalLoad();
        setbatchItems([]);
        const response = await ApiJob.refreshBatchItemByMat({ dpno: dsDetail.dpno, matCode: itemMaterial.matCode });
        if (response.status == 200) {
            console.log("response", response.data);
            setisChangeBatch(false)
            setbatchItems(response.data);
            CSwl.SwalClose();
            //refreshData();
        }
        else {
            CSwl.SwalErr(`${response.data}`);
        }
        //setbatchItems([]);
        //refreshData();
        //setisChangeBatch(false)
    }

    return <><tr>
        <td className="text-center">{indexMaterial + 1}</td>
        {(pageType == "delivery" || pageType == "complete") ? <td onClick={() =>
            setactive(!active)
        }>
            <div className="flex btn-link items-center justify-between">
                <span>{itemMaterial.matCode}</span>
                <ChevronDownIcon
                    className={`${active ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-purple-500`}
                />
            </div>
        </td> :
            <td>
                <div className="flex btn-link items-center justify-between">
                    <span>{itemMaterial.matCode}</span>
                </div>
            </td>
        }
        {
            pageType == "pickup" && <>
                <td>{Currency(itemMaterial.qty)}</td>
                <td>{Currency(dsDetail.dpDetails[0].weightUnit == "KG" ? itemMaterial.weight / 1000 : itemMaterial.weight, 3)}</td>
            </>
        }
        {
            pageType == "delivery" && <>
                <td>{(batchItems.length > 0 ? unit == "ROL" || unit == "TON" || unit == "KG" ? batchItems.length : sumBy(batchItems, unit == "ROL" || unit == "TON" || unit == "KG" ? 'weight' : 'qty') : itemMaterial.qty)}</td>
                <td>{Currency(unitton == "KG" || unitton == "KG" ? itemMaterial.weight / 1000 : itemMaterial.weight / 1000, 3)}</td>
            </>
        }

    </tr>
        {active &&
            <tr>
                <td className="!p-0" colSpan={4}>
                    <table class="table-auto border-collapse border border-slate-500 w-full">
                        <thead>
                            <tr>
                                <th className="" style={{ width: '27px' }}></th>
                                <th className="">Batch</th>
                                <th className="text-left" style={{ width: '70px' }}>{unit == "ROL" || unit == "TON" ? "นน สินค้า" : "จำนวน"}</th>
                                <th className="text-left" style={{ width: '70px' }}>{unit == "ROL" || unit == "TON" ? "นน รับ" : "จำนวนรับ"}</th>
                            </tr>
                        </thead>
                        <tbody className="pb-2">
                            {
                                batchItems.map((item, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td></td>
                                                <td className="px-2">{item.batchno}</td>
                                                <td>{unit == "ROL" || unit == "TON" ? parseFloat(item.weight ?? 0).toFixed(3) : parseFloat(item.qty ?? 0)}</td>
                                                <td>
                                                    {
                                                        unit == "ROL" || unit == "TON" ? <input
                                                            disabled={dsDetail.isEdn || pageType == "complete" || dsDetail.whStatus == "Complete" ? true : false}
                                                            readOnly={dsDetail.isEdn || pageType == "complete" || dsDetail.whStatus == "Complete" ? true : false}
                                                            onChange={(e) => {
                                                                const batchChanged = [...batchItems];
                                                                if (e.target.value >= item.weight) {
                                                                    batchChanged[index].weightConfirm = item.weight;
                                                                    batchChanged[index].comment = null;
                                                                    batchChanged[index].commentNote = "";
                                                                }
                                                                else {
                                                                    batchChanged[index].weightConfirm = e.target.value;
                                                                }

                                                                setbatchItems(batchChanged)
                                                                setisChangeBatch(true)
                                                            }}
                                                            pattern="^\d*(\.\d{0,3})?$"
                                                            value={item.weightConfirm != null ? item.weightConfirm : null}
                                                            defaultValue={parseFloat(item.weight ?? 0).toFixed(3)}
                                                            step=".001"
                                                            type={"number"}
                                                            className="border-b border-black mb-1" style={{ width: '50px' }} />
                                                            : <input
                                                                disabled={dsDetail.isEdn || pageType == "complete" || dsDetail.whStatus == "Complete" ? true : false}
                                                                readOnly={dsDetail.isEdn || pageType == "complete" || dsDetail.whStatus == "Complete" ? true : false}
                                                                onChange={(e) => {
                                                                    const batchChanged = [...batchItems];
                                                                    if (e.target.value >= item.qty) {
                                                                        batchChanged[index].qtyConfirm = item.qty;
                                                                        batchChanged[index].comment = null;
                                                                        batchChanged[index].commentNote = "";
                                                                    }
                                                                    else {
                                                                        batchChanged[index].qtyConfirm = e.target.value;
                                                                    }
                                                                    setbatchItems(batchChanged)
                                                                    setisChangeBatch(true)
                                                                }}
                                                                pattern="^\d*(\.\d{0,3})?$"
                                                                value={item.qtyConfirm != null ? item.qtyConfirm : null}
                                                                defaultValue={parseFloat(item.qty ?? 0)}
                                                                step="1"
                                                                type={"number"}
                                                                className="border-b border-black mb-1" style={{ width: '50px' }} />
                                                    }
                                                </td>
                                            </tr>
                                            {
                                                ((item.weightConfirm != null && item.weight != item.weightConfirm) || (item.qtyConfirm != null && item.qty != item.qtyConfirm)) &&
                                                <tr className="mt-1">
                                                    <td></td>
                                                    <td className="px-2">เหตุผล</td>
                                                    <td colSpan={2}>
                                                        {/* <select className="border-b border-black mb-1" style={{ width: '120px' }}>
                                                        <option value="เปียกน้ำ">เปียกน้ำ</option>
                                                    </select> */}
                                                        <select
                                                            onChange={(e) => {
                                                                const batchChanged = [...batchItems];
                                                                batchChanged[index].comment = e.target.value == "" ? null : e.target.value;
                                                                batchChanged[index].commentNote = commentMat.find(x => x.id == e.target.value)?.commentdesc;
                                                                setbatchItems(batchChanged)
                                                                setisChangeBatch(true)
                                                            }}
                                                            disabled={dsDetail.isEdn || pageType == "complete" || dsDetail.whStatus == "Complete" ? true : false}
                                                            required
                                                            value={item.comment}
                                                            className="border-b border-black mb-1 w-[120px]">
                                                            <option value={""}>เลือกเหตุผล</option>
                                                            {
                                                                commentMat.map((item, index) => {
                                                                    return (
                                                                        <option value={item.id}>{item.commentdesc}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            }

                                        </>)
                                })
                            }

                        </tbody>
                    </table>
                </td>
            </tr>
        }
        {
            (pageType == "delivery" && dsDetail.whStatus != "Complete") && <tr>
                <td colSpan={4} className="p-2">
                    <div className="flex justify-end gap-x-2">
                        {
                            !(dsDetail.dpno?.length == 12) && 
                            <button type="button" className="btn-blue" onClick={() => {
                                console.log(dsDetail)
                                //refreshBatchItem();
                            }}>รีเฟรช</button>
                        }

                        {
                            !dsDetail.isEdn && <button className="btn-blue" onClick={() => {
                                saveBatchItem();
                            }}>บันทึก</button>
                        }
                    </div>
                </td>
            </tr>
        }
    </>
}

const JobDetailTab2 = ({ shipmentNo, photos = [] }) => {
    const [photosDefault, setphotos] = useState(photos);
    const navigate = useNavigate();
    const [shipmentImage, setfile] = useState('');
    const uploadImage = async (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (await CheckFile({ file: file, size: 10, type: ['image/gif', 'image/jpeg', 'image/png'] })) {
                CSwl.SwalLoad();
                setfile(file);
                const response = await ApiImage.uploadImage(file);
                if (response.status == 200) {
                    const responseSaveImage = await ApiImage.SaveJobImage({
                        "shipmentNo": shipmentNo,
                        "photoName": response.data.filename,
                        "photoThumbnailName": response.data.thumbnainlname,
                        "photoType": 1,
                    });
                    if (responseSaveImage.status == 200) {
                        console.log("photosDefault:", photosDefault)
                        console.log("responseSaveImage:", responseSaveImage)
                        setfile('');
                        setphotos([...photosDefault, { id: responseSaveImage.data.imgid, photoThumbnailName: responseSaveImage.data.thumbnailName }])
                    }
                }
                CSwl.SwalClose();
            }
        }
    }
    const confirmShipment = async (shipmentNo) => {
        CSwl.SwalLoad();
        const response = await ApiJob.confirmShipment(shipmentNo);
        if (response.status == 200) {
            CSwl.SwalClose();
            navigate('/jobTimeline');
        }
        else {
            CSwl.SwalErr(response.data);
        }
    }


    const deleteImage = async (id) => {
        CSwl.SwalLoad();

        const response = await ApiImage.deleteImageShipment(id);
        if (response.status == 200) {
            setphotos(photosDefault.filter(x => x.id != id))
            CSwl.SwalClose();
        }
        else {
            CSwl.SwalClose();
        }
    }

    return <Disclosure defaultOpen={true}>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span>ภาพก่อนคลุมผ้าใบ</span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            {shipmentImage ? <>
                                <span class="mt-2 text-base leading-normal">Upload a file ({shipmentImage.name})</span>
                                <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            </> : <>
                                <span class="mt-2 text-base leading-normal">Upload a file</span>
                                <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            </>}
                            <input type='file' class="hidden" onChange={uploadImage} />
                        </label>
                    </div>
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        {
                            photosDefault.map((item, index) => {
                                return <div className="relative">
                                    <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                    <div onClick={() => {
                                        deleteImage(item.id)
                                    }} className="p-2 cursor-pointer absolute top-0 right-0 bg-white border border-[#cfcfcf]">
                                        <TrashIcon
                                            className={`h-5 w-5 text-red`}
                                        />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="flex justify-center">
                        <button className="btn-green block mt-2 mr-2" onClick={() => { confirmShipment(shipmentNo) }}>
                            <div className="flex items-center justify-center">
                                <span>ตกลงขึ้นสินค้าเรียบร้อยแล้ว</span>
                            </div>
                        </button>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const JobDetailTab2Detail = ({ photos = [], pageType }) => {
    return <Disclosure defaultOpen={true}>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span>ภาพก่อนคลุมผ้าใบ</span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                {
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                        {
                            photos.map((item, index) => {
                                return <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                            })
                        }
                    </Disclosure.Panel>
                }

            </>
        )}
    </Disclosure>
}

const JobDeliveryInformation = ({ header, dsDetails, shiptoid, shipto, shipmentNo, photos = [], pageType, refreshData, dpIndex = 0, lengthDP = 0, dpItem }) => {
    const adminLogin = useSelector((state) => state.adminLogin);
    const isChangeBatch = useSelector((state) => state.isChangeBatch);
    const signatureRef = useRef(null);
    const [photosDefault, setphotos] = useState(photos);
    const navigate = useNavigate();
    const [shipmentImage, setfile] = useState('');
    const [contact, setContact] = useState([]);
    const [contactSelected, setcontactSelected] = useState('');
    const [signature, setsignature] = useState('');
    const [modal, setmodal] = useState(false);
    const [commentSelected, setcommentSelected] = useState([]);
    const [contactName, setcontactName] = useState();
    const [state, setstate] = useState({
        otp: "",
        confirmOTP: "",
        messageErrorOTP: "",
        isCheckupDriver: false,
        isCheckupTruck: false,
        comments: "",
        otherTruck: "",
        otherDriver: ""
    });
    const uploadImage = async (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (await CheckFile({ file: file, size: 10, type: ['image/gif', 'image/jpeg', 'image/png'] })) {
                CSwl.SwalLoad();
                setfile(file);
                const response = await ApiImage.uploadImage(file);
                if (response.status == 200) {
                    const responseSaveImage = await ApiImage.SaveJobImage({
                        "shipmentNo": shipmentNo,
                        "photoName": response.data.filename,
                        "photoThumbnailName": response.data.thumbnainlname,
                        "photoType": 3,
                        "shipto": shiptoid,
                        "dpNo": dpItem.dpno
                    });
                    if (responseSaveImage.status == 200) {
                        setfile('');
                        setphotos([...photosDefault, { id: responseSaveImage.data.imgid, photoThumbnailName: responseSaveImage.data.thumbnailName, photoType: 3, shipto: shiptoid, dpNo: dpItem.dpno }])
                        CSwl.SwalClose();
                        // refreshData();
                    }
                    else {
                        CSwl.SwalClose();
                    }
                }
                else {
                    CSwl.SwalClose();
                }
            }
        }
    }

    const uploadImage2 = async (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (await CheckFile({ file: file, size: 10, type: ['image/gif', 'image/jpeg', 'image/png'] })) {
                CSwl.SwalLoad();
                setfile(file);
                const response = await ApiImage.uploadImage(file);
                if (response.status == 200) {
                    const responseSaveImage = await ApiImage.SaveJobImage({
                        "shipmentNo": shipmentNo,
                        "photoName": response.data.filename,
                        "photoThumbnailName": response.data.thumbnainlname,
                        "photoType": 4,
                        "shipto": shiptoid,
                        "dpNo": dpItem.dpno
                    });
                    if (responseSaveImage.status == 200) {
                        setfile('');
                        setphotos([...photosDefault, { id: responseSaveImage.data.imgid, photoThumbnailName: responseSaveImage.data.thumbnailName, photoType: 4, shipto: shiptoid, dpNo: dpItem.dpno }])
                        CSwl.SwalClose();
                        // refreshData();
                    }
                    else {
                        CSwl.SwalClose();
                    }
                }
                else {
                    CSwl.SwalClose();
                }
            }
        }
    }
    const uploadImage3 = async (e, comment) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (await CheckFile({ file: file, size: 10, type: ['image/gif', 'image/jpeg', 'image/png'] })) {
                CSwl.SwalLoad();
                setfile(file);
                const response = await ApiImage.uploadImage(file);
                if (response.status == 200) {
                    const responseSaveImage = await ApiImage.SaveJobImage({
                        "shipmentNo": shipmentNo,
                        "photoName": response.data.filename,
                        "photoThumbnailName": response.data.thumbnainlname,
                        "photoType": 5,
                        "shipto": shiptoid,
                        "comment": comment,
                        //"dpNo": dpItem.dpno
                    });
                    if (responseSaveImage.status == 200) {
                        setfile('');
                        CSwl.SwalClose();
                        setphotos([...photosDefault, { id: responseSaveImage.data.imgid, comment: comment, photoThumbnailName: responseSaveImage.data.thumbnailName, photoType: 5, dpNo: dpItem.dpno }])
                        // refreshData();
                    }
                    else {
                        CSwl.SwalClose();
                    }
                }
                else {
                    CSwl.SwalClose();
                }
            }
        }
    }
    const uploadSignImage = async (e) => {
        const image = signatureRef.current.getTrimmedCanvas().toDataURL('image/jpg');
        CSwl.SwalLoad();
        const response = await ApiImage.uploadImageBase64(image);
        if (response.status == 200) {
            const responseSaveImage = await ApiImage.SaveJobImage({
                "shipmentNo": shipmentNo,
                "photoName": response.data.filename,
                "photoThumbnailName": response.data.thumbnainlname,
                "photoType": 2,
                "shipto": shiptoid,
                "signatureBy": contactName,
            });
            if (responseSaveImage.status == 200) {
                setfile('');
                setsignature(responseSaveImage.data.thumbnailName);
                setphotos([...photosDefault, { id: responseSaveImage.data.imgid, photoThumbnailName: responseSaveImage.data.thumbnailName, photoType: 2 }])
                CSwl.SwalClose();
                // refreshData();
                signatureRef.current.clear();
            }
            else {
                CSwl.SwalClose();
            }
        }
    }

    const commentDp = useSelector((state) => state.commentDp);

    const deleteImage = async (id) => {
        CSwl.SwalLoad();

        const response = await ApiImage.deleteImageShipment(id);
        if (response.status == 200) {
            setphotos(photosDefault.filter(x => x.id != id))
            CSwl.SwalClose();
        }
        else {
            CSwl.SwalClose();
        }
    }

    const finishDelivery = async () => {
        if (isChangeBatch) {
            CSwl.SwalErr(`มีการเปลี่ยนแปลงน้ำหนัก กรุณาบันทึกก่อนกดรับสินค้า`);
        } else {
            CSwl.SwalLoad();
            navigator.geolocation.getCurrentPosition(async function (position) {
                try {
                    const response = await ApiJob.finishDelivery({
                        "shipmentNo": shipmentNo,
                        "shipto": shiptoid,
                        "signature": signature,
                        "signatureBy": contactSelected?.officerCode,
                        "soldto": dpItem.soldto,
                        lat: position.coords.latitude.toString(),
                        lng: position.coords.longitude.toString(),
                        comments: state.comments,
                        isCheckupDriver: state.isCheckupDriver,
                        isCheckupTruck: state.isCheckupTruck,
                        otherDriver: state.otherDriver,
                        otherTruck: state.otherTruck
                    });
                    if (response.status == 200) {
                        setfile('');
                        refreshData();
                    }
                    else {
                        CSwl.SwalErr(`${response.data}`);
                    }

                } catch (error) {
                    const { data } = error.response;
                    CSwl.SwalErr(data.message)
                }
            }, (error) => {
                const { message } = error;

                CSwl.SwalErr(message)
            }, {
                enableHighAccuracy: true
            });
        }



    }

    const finishAdmin = async () => {
        try {
            const response = await ApiJob.finishAdmin({
                "shipmentNo": shipmentNo,
                "shipto": shiptoid,
                "signature": signature,
                "signatureBy": contactSelected?.officerCode ?? contactName,
                "soldto": dpItem.soldto
            });
            if (response.status == 200) {
                setfile('');
                refreshData();
            }
            else {
                CSwl.SwalErr(`${response.data}`);
            }

        } catch (error) {
            const { data } = error.response;
            CSwl.SwalErr(data.message)
        }
    }

    const genarateReport = async (shipmentNo, shipto) => {
        CSwl.SwalLoad();
        const response = await ApiJob.reportShipment(shipmentNo, shipto);
        if (response.status == 200) {
            if (response.data.Message) {
                response.data.Message.map((item) => {
                    // window.open(process.env.REACT_APP_IMAGE + item, "_blank");
                    window.open(item, "_blank")
                })
            }
        }
        CSwl.SwalClose();
    }

    useEffect(() => {
        getSignature();
        console.log('photos', photos)
        console.log('header.dsDetails', header.dsDetails)
    }, []);

    useEffect(() => {
        if (state.isCheckupDriver && state.isCheckupTruck) {
            setstate({ ...state, comments: "" })
            setcommentSelected([])
        }
    }, [state.isCheckupDriver, state.isCheckupTruck]);

    const getSignature = async () => {
        const result = await ApiJob.getSignature({ shipto: shiptoid });
        if (result.status == 200) {
            if (result.data.contractName) {
                setContact([{
                    name: result.data.contractName,
                    signature: result.data.contractSignature,
                    mobile: result.data.contractMobile
                }])
            }
        }
    }

    useEffect(() => {
        if (contactSelected?.officerCode) {
            setmodal(true);
            //requestOTP();
        } else {
            setmodal(false);
            setsignature('');
        }
    }, [contactSelected]);

    const requestOTP = async () => {
        CSwl.SwalLoad();
        const response = await ApiUser.otpRequestRegister({
            "officer_code": "",
            "phoneNumber": contactSelected.mobile,
        });
        if (response.status == 200) {
            CSwl.SwalClose();
            CSwl.SwalSuccess();
            setstate({ ...state, messageError: "", otp: response.data })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
    }

    const confirmOTP = async () => {
        if (state.confirmOTP !== contactSelected.pincode) {
            CSwl.SwalErr(`Pincode ไม่ถูกต้อง`);
        } else {
            setmodal(false);
            setsignature(contactSelected?.signDomainPath)
            setstate({ ...state, confirmOTP: '' })
        }
        // const response = await ApiUser.comparePincode({
        //     oldPasswordHash: contactSelected.officerPasswordHash,
        //     confirmPassword: state.confirmOTP
        // })
        // if (response.status == 200) {
        //     setmodal(false);
        //     setsignature(contactSelected?.signDomainPath)
        // }
        // else {
        //     CSwl.SwalErr(`${response.data}`);
        // }
        // if (state.otp != state.confirmOTP) {
        //     setstate({ ...state, messageErrorOTP: "ยืนยันรหัส OTP ไม่ถูกต้อง" });
        //     CSwl.SwalClose();
        //     return false;
        // } else {
        //     setmodal(false);
        //     setsignature(contactSelected?.signature)
        // }
    }

    useEffect(() => {
        if (pageType == "delivery" && dpItem.whStatus != "Complete") {
            deleteImageComment(commentSelected)
        }
    }, [commentSelected]);

    const deleteImageComment = async (comments) => {
        CSwl.SwalLoad();
        const response = await ApiImage.deleteImageComment({
            "shipmentNo": shipmentNo,
            "shipto": shiptoid,
            "comment": comments.map(x => x.value).join(",")
        });
        if (response.status == 200) {
            const { ids } = response.data;
            setphotos(photosDefault.filter(x => !ids.includes(x.id)))
            CSwl.SwalClose();
        }
        else {
            CSwl.SwalClose();
        }
    }

    return <>
        <Disclosure defaultOpen>
            {({ open }) => (
                <>
                    <Disclosure.Button className={`flex w-full justify-between
        ${open ? 'rounded-bl-none rounded-br-none ' : ''}
        bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                        <span>ส่วนของพนักงานขับรถ</span>
                        <ChevronDownIcon
                            className={`${open ? 'rotate-180 transform' : ''
                                } h-5 w-5 text-purple-500`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                        <div class={`mt-2 flex flex-col w-full items-center justify-center bg-grey-lighter ${pageType == "complete" || dpItem.whStatus == "Complete" ? "hidden" : ""}`}>
                            <label className="self-start">อัพโหลดก่อนลงสินค้า*</label>
                            <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                                <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                {shipmentImage ? <>
                                    <span class="mt-2 text-base leading-normal">Upload a file ({shipmentImage.name})</span>
                                    <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                                </> : <>
                                    <span class="mt-2 text-base leading-normal">Upload a file</span>
                                    <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                                </>}
                                <input type='file' class="hidden" onChange={uploadImage2} />
                            </label>
                        </div>
                        <div>
                            <label>ภาพก่อนลงสินค้า</label>
                            <div class="flex w-full items-center justify-center bg-grey-lighter my-3 flex-wrap gap-2">
                                {
                                    photosDefault.filter(x => x.photoType == 4 && x.shipto == shiptoid && x.dpNo == dpItem.dpno).map((item, index) => {
                                        console.log(dpItem.dpno)
                                        return <div className="relative">
                                            <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                            {
                                                pageType == "delivery" && dpItem.whStatus != "Complete" && <div onClick={() => {
                                                    deleteImage(item.id)
                                                }} className="p-2 cursor-pointer absolute top-0 right-0 bg-white border border-[#cfcfcf]">
                                                    <TrashIcon
                                                        className={`h-5 w-5 text-red`}
                                                    />
                                                </div>
                                            }


                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div class={`mt-2 flex-col flex w-full items-center justify-center bg-grey-lighter ${pageType == "complete" || dpItem.whStatus == "Complete" ? "hidden" : ""}`}>
                            <label className="self-start">อัพโหลด DP/ใบส่งสินค้า*</label>
                            <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                                <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                {shipmentImage ? <>
                                    <span class="mt-2 text-base leading-normal">Upload a file ({shipmentImage.name})</span>
                                    <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                                </> : <>
                                    <span class="mt-2 text-base leading-normal">Upload a file</span>
                                    <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                                </>}
                                <input type='file' class="hidden" onChange={uploadImage} />
                            </label>
                        </div>

                        <div>
                            <label>ภาพ DP/ใบส่งสินค้า</label>
                            <div class="flex w-full items-center justify-center bg-grey-lighter my-3 flex-wrap gap-2">
                                {
                                    photosDefault.filter(x => x.photoType == 3 && x.shipto == shiptoid && x.dpNo == dpItem.dpno).map((item, index) => {
                                        return <div className="relative">
                                            <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                            {
                                                pageType == "delivery" && dpItem.whStatus != "Complete" && <div onClick={() => {
                                                    deleteImage(item.id)
                                                }} className="p-2 cursor-pointer absolute top-0 right-0 bg-white border border-[#cfcfcf]">
                                                    <TrashIcon
                                                        className={`h-5 w-5 text-red`}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        {((pageType == "complete" || dpItem.whStatus == "Complete") && dpItem.isEdp == false && dpItem.isEdn == false) && <>
                            {
                                <>
                                    {header.signatureByList.find(x => x.shipto == shiptoid.padStart(10, '0') || x.shipto == shiptoid)?.officerName ? <div className="flex">
                                        <UserIcon
                                            className={`h-5 w-5 mr-1`}
                                        />
                                        <span className="text-bold w-16">ชื่อผู้รับ</span>
                                        <div className="flex">

                                            <span>{header.signatureByList.find(x => x.shipto == shiptoid.padStart(10, '0') || x.shipto == shiptoid)?.officerName}</span>
                                        </div>
                                    </div> : <div className="flex">
                                        <UserIcon
                                            className={`h-5 w-5 mr-1`}
                                        />
                                        <span className="text-bold w-16">ชื่อผู้รับ</span>
                                        <div className="flex">

                                            <span>-</span>
                                        </div>
                                    </div>}

                                    <div className="flex">
                                        <ClockIcon
                                            className={`h-5 w-5 mr-1`}
                                        />
                                        <span className="text-bold w-16">เวลาที่ส่ง</span>
                                        <div className="flex">
                                            <span>{ConvertDate(dpItem.tranLtmswh?.updateDate, 'DD/MM/YYYY HH:mm:ss', 'en', 0)}</span>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <LocationMarkerIcon
                                            className={`h-5 w-5 mr-1`}
                                        />
                                        <span className="text-bold w-16">สถานที่ส่ง</span>
                                        <div className="flex">
                                            <span>{dpItem.tranLtmswh?.lat},{dpItem.tranLtmswh?.lng}</span>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <LocationMarkerIcon
                                            className={`h-5 w-5 mr-1`}
                                        />
                                        <span className="text-bold w-16">สถานที่รับ</span>
                                        <div className="flex">
                                            <span>{dpItem.tranLtmswh?.lat},{dpItem.tranLtmswh?.lng}</span>
                                        </div>
                                    </div>
                                </>
                            }</>}
                    </Disclosure.Panel>
                </>
            )}

        </Disclosure>
        {
            (dsDetails.isEdp == false) ? <div>
                {/* {
                    (adminLogin && pageType == "complete") && <div className="mt-2 ">
                        <label className="text-blue text-bold">ชื่อผู้รับสินค้า</label>
                        {
                            ((dpItem?.contactInfo == null || dpItem?.contactInfo.length == 0)) && <>
                                <div className="p-3 border relative mt-3 flex-1" style={{
                                    width: "auto",
                                }}>
                                    <SignatureCanvas penColor='black'
                                        ref={signatureRef}
                                        canvasProps={{ className: 'sigCanvas w-full h-32' }}
                                    />

                                </div>
                                <div className="flex py-2">
                                    <input placeholder="ชื่อ-นามสกุล"
                                        onChange={(e) => {
                                            setcontactName(e.target.value)
                                        }}
                                        className="form-control" value={contactName} />
                                </div>
                                <div className="flex py-2">
                                    <button className="flex justify-center items-center text-2xl"
                                        onClick={() => {
                                            signatureRef.current.clear();
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>

                                    </button>
                                    <div className="rounded-md">
                                        <button className={`btn-blue ml-2`} onClick={uploadSignImage}>
                                            ยืนยันลายเซ็น
                                        </button>
                                    </div>
                                </div>
                                {
                                    pageType == "complete" && photosDefault.filter(x => x.photoType == 2).map((item, index) => {
                                        return <img className="h-32 w-60" src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                    })
                                }
                            </>
                        }
                    </div>
                } */}
                <div className="flex justify-center my-2 gap-x-2">
                    {
                        pageType == "delivery" && dpItem.whStatus != "Complete" &&
                        <button disabled={!state.comments && !(state.isCheckupDriver && state.isCheckupTruck) && !dpItem.isEdn && dpItem.isEdp == true}
                            className="btn-blue ml-2" onClick={() => { finishDelivery() }}>
                            {(dpItem.isEdp == false) ? "บันทึกข้อมูล" : "ยืนยันการรับสินค้า"}
                        </button>
                    }
                    {/* {
                        pageType == "complete" && adminLogin && <button className="btn-blue ml-2" onClick={() => { finishAdmin() }}>บันทึกข้อมูล</button>
                    } */}
                    {
                        (pageType == "complete" && !(shipmentNo?.length == 12)) && <button className="btn-blue ml-2" onClick={() => { genarateReport(shipmentNo, shiptoid) }}>ใบจ่ายสินค้า</button>
                    }
                </div>

            </div> :
                <>

                    {dpIndex == lengthDP && <Disclosure defaultOpen>
                        {({ open }) => (
                            <>
                                <Disclosure.Button
                                    className={`flex w-full justify-between
                       ${open ? 'rounded-bl-none rounded-br-none ' : ''}
                       bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}
                                >
                                    <span>ส่วนของลูกค้า {shipto}</span>
                                    <ChevronDownIcon
                                        className={`${open ? 'rotate-180 transform' : ''
                                            } h-5 w-5 text-purple-500`}
                                    />
                                </Disclosure.Button>

                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                                    {
                                        ((pageType == "delivery") && dpItem.isEdn == false && dpItem.isEdp == true) && <div className="flex mt-4 gap-4 flex-wrap">
                                            <div className="flex gap-x-2">
                                                <input onChange={(e) => {
                                                    setstate({ ...state, isCheckupDriver: e.target.checked })
                                                }} disabled={dpItem.isEdn} id="default-checkbox" type="checkbox" value={state.isCheckupDriver} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="default-checkbox" class="font-medium text-gray-900 dark:text-gray-300">ความพร้อมของพนักงานขับรถ</label>
                                            </div>
                                            <div className="flex gap-x-2">
                                                <input onChange={(e) => {
                                                    setstate({ ...state, isCheckupTruck: e.target.checked })
                                                }} disabled={dpItem.isEdn} id="default-checkbox-2" type="checkbox" value={state.isCheckupTruck} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="default-checkbox-2" class="font-medium text-gray-900 dark:text-gray-300">ความพร้อมของรถบรรทุก</label>
                                            </div>
                                        </div>
                                    }
                                    {
                                        ((pageType == "complete") && (dpItem.tranMain.isCheckupTruck == 1 || dpItem.tranMain.isCheckupDriver == 1)) && <div className="flex mt-4 gap-4 flex-wrap">
                                            <div className="flex gap-x-2">
                                                <input checked={dpItem.tranMain.isCheckupDriver == 1 ? true : false} disabled id="default-checkbox" type="checkbox" value={dsDetails.tranMain.isCheckupDriver} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="default-checkbox" class="font-medium text-gray-900 dark:text-gray-300">ความพร้อมของพนักงานขับรถ</label>
                                            </div>
                                            <div className="flex gap-x-2">
                                                <input checked={dpItem.tranMain.isCheckupTruck == 1 ? true : false} disabled id="default-checkbox-2" type="checkbox" value={dsDetails.tranMain.isCheckupTruck} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="default-checkbox-2" class="font-medium text-gray-900 dark:text-gray-300">ความพร้อมของรถบรรทุก</label>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (dpItem.isEdn == false && dpItem.isEdp == true) && <div className="my-3 gap-4 flex-wrap">
                                            <label>รายละเอียดการร้องเรียน / การกระทำ</label>
                                            {
                                                (pageType == "complete" || dpItem.whStatus == "Complete") && dpItem.tranEdpcomments.length == 0 ?
                                                    <input readOnly className="form-control" value="-" /> :
                                                    <>
                                                        <Select
                                                            key="comments"
                                                            isDisabled={!(pageType == "delivery" && dpItem.whStatus != "Complete") || (state.isCheckupDriver && state.isCheckupTruck) || dsDetails.isEdn}
                                                            className={"basic-multi-select"}
                                                            defaultValue={commentDp.map(x => {
                                                                return {
                                                                    value: x.id,
                                                                    label: x.commentdesc
                                                                }
                                                            }).filter(x => dpItem.tranEdpcomments.includes(x.value))}
                                                            value={pageType == "complete" ? commentDp.map(x => {
                                                                return {
                                                                    value: x.id,
                                                                    label: x.commentdesc
                                                                }
                                                            }).filter(x => dpItem.tranEdpcomments?.includes(x.value)) : commentSelected}
                                                            isMulti
                                                            name="comments"
                                                            options={commentDp.filter(x => ((x.dptype == "T" || !state.isCheckupDriver) && (x.dptype == "D" || !state.isCheckupTruck)) || (!state.isCheckupDriver && !state.isCheckupTruck)).map(x => {
                                                                return {
                                                                    value: x.id,
                                                                    label: x.commentdesc
                                                                }
                                                            })}
                                                            // onChange={(e) => changeComment(e, dsDetails)}
                                                            onChange={(e) => {
                                                                if (e.length == 0) {
                                                                    setstate({ ...state, comments: '' })
                                                                    setcommentSelected([])
                                                                } else {
                                                                    setstate({ ...state, comments: e.map(x => x.value).join(",") })
                                                                    setcommentSelected(e)
                                                                }
                                                            }
                                                            }
                                                            //className="basic-multi-select"
                                                            classNamePrefix="select"
                                                        />
                                                        {
                                                            state.comments && !state.isCheckupDriver && state.comments.split(',').includes('17') && <input
                                                                value={state.otherDriver}
                                                                onChange={(e) => {
                                                                    setstate({ ...state, otherDriver: e.target.value })
                                                                }}
                                                                placeholder="โปรดระบุ (พนักงานขับรถ)"
                                                                className="form-control" />
                                                        }
                                                        {
                                                            state.comments && !state.isCheckupTruck && state.comments.split(',').includes('22') && <input
                                                                value={state.otherTruck}
                                                                onChange={(e) => {
                                                                    setstate({ ...state, otherTruck: e.target.value })
                                                                }}
                                                                placeholder="โปรดระบุ (ความพร้อมรถบรรทุก)"
                                                                className="form-control" />
                                                        }
                                                    </>
                                            }

                                        </div>
                                    }

                                    {
                                        state.comments && <>
                                            {
                                                state.comments.split(",").map((item, index) => {
                                                    return <><div class={`mt-2 flex-col flex w-full items-center justify-center bg-grey-lighter ${pageType == "complete" ? "hidden" : ""}`}>
                                                        <label className="self-start">อัพโหลดภาพประกอบ {`(${commentDp.find(x => x.id == item)?.commentdesc})`}</label>
                                                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                                                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                            </svg>
                                                            {shipmentImage ? <>
                                                                <span class="mt-2 text-base leading-normal">Upload a file ({shipmentImage.name})</span>
                                                                <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                                                            </> : <>
                                                                <span class="mt-2 text-base leading-normal">Upload a file</span>
                                                                <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                                                            </>}
                                                            <input type='file' class="hidden" onChange={(e) => {
                                                                uploadImage3(e, item)
                                                            }} />
                                                        </label>
                                                    </div>
                                                        {
                                                            photosDefault.filter(x => x.photoType == 5 && x.comment == item)?.length > 0 && <div>
                                                                {/* <label>ภาพประกอบการร้องเรียน</label> */}
                                                                <div class="flex w-full items-center justify-center bg-grey-lighter mt-3 flex-wrap gap-2">
                                                                    {
                                                                        photosDefault.filter(x => x.photoType == 5 && x.comment == item).map((item, index) => {
                                                                            return <div className="relative">
                                                                                <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                                                                {
                                                                                    pageType == "delivery" && <div onClick={() => {
                                                                                        deleteImage(item.id)
                                                                                    }} className="p-2 cursor-pointer absolute top-0 right-0 bg-white border border-[#cfcfcf]">
                                                                                        <TrashIcon
                                                                                            className={`h-5 w-5 text-red`}
                                                                                        />
                                                                                    </div>
                                                                                }


                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                })
                                            }

                                        </>
                                    }

                                    {
                                        dpItem.tranEdpcomments?.length > 0 && <>
                                            {
                                                dpItem.tranEdpcomments.map((item, index) => {
                                                    return photosDefault.filter(x => x.photoType == 5 && x.comment == item && (x.shipto == shiptoid.padStart(10, '0') || x.shipto == shiptoid))?.length > 0 && <div>
                                                        {/* <label>ภาพประกอบการร้องเรียน</label> */}
                                                        <div class="flex w-full items-center justify-center bg-grey-lighter mt-3 flex-wrap gap-2">
                                                            {
                                                                photosDefault.filter(x => x.photoType == 5 && x.comment == item && (x.shipto == shiptoid.padStart(10, '0') || x.shipto == shiptoid)).map((item, index) => {
                                                                    return <div className="relative">
                                                                        <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                                                        {
                                                                            pageType == "delivery" && <div onClick={() => {
                                                                                deleteImage(item.id)
                                                                            }} className="p-2 cursor-pointer absolute top-0 right-0 bg-white border border-[#cfcfcf]">
                                                                                <TrashIcon
                                                                                    className={`h-5 w-5 text-red`}
                                                                                />
                                                                            </div>
                                                                        }


                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                })

                                            }
                                        </>
                                    }

                                    {/* {
                                pageType == "complete" &&
                                <div class="flex w-full items-center justify-center bg-grey-lighter mt-3 flex-wrap gap-2">
                                    {
                                        photosDefault.filter(x => x.photoType == 5).map((item, index) => {
                                            return <div className="relative">
                                                <span>{`(${commentDp.find(x => x.id == item.comment)?.commentdesc})`}</span>
                                                <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                                {
                                                    pageType == "delivery" && <div onClick={() => {
                                                        deleteImage(item.id)
                                                    }} className="p-2 cursor-pointer absolute top-0 right-0 bg-white border border-[#cfcfcf]">
                                                        <TrashIcon
                                                            className={`h-5 w-5 text-red`}
                                                        />
                                                    </div>
                                                }


                                            </div>
                                        })
                                    }
                                </div>
                            } */}







                                    {
                                        (dpItem?.contactInfo?.length > 0 && pageType == "delivery" && dpItem.whStatus != "Complete") && <div className="mt-2">
                                            <label className="text-blue text-bold">ชื่อผู้รับสินค้า</label>
                                            <select
                                                name="signature"
                                                value={contactSelected.officerCode ?? ""}
                                                onChange={(e) => {
                                                    setcontactSelected(dpItem?.contactInfo.find(x => x.officerCode == e.target.value) ?? "")
                                                }} className="form-control">
                                                <option value="">เลือกผู้ติดต่อ</option>
                                                {
                                                    dpItem?.contactInfo.map((item, index) => {
                                                        return <option value={item.officerCode}>{item.officerFirstnameTha}</option>
                                                    })
                                                }
                                            </select>


                                            {
                                                ((signature && pageType !== "complete")) && <div class="flex w-full items-center justify-center bg-grey-lighter my-3">
                                                    <img className="h-32 w-60" src={process.env.REACT_APP_IMAGE + signature} />
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        pageType == "complete" && !signature && <div className="my-3 ">
                                            <label>ภาพลายเซ็น</label>
                                            <div class="flex w-full items-center justify-center bg-grey-lighter flex-wrap gap-2">
                                                {
                                                    photosDefault.filter(x => x.photoType == 2 && x.shipto == shiptoid.padStart(10, '0') || x.shipto == shiptoid).map((item, index) => {
                                                        // console.log("sign::",shiptoid)
                                                        // console.log("sign::",item)
                                                        if (index == 0)
                                                            return <div className="relative">
                                                                <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                                            </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        ((dpItem?.contactInfo == null || dpItem?.contactInfo.length == 0) && pageType == "delivery" && dpItem.isEdn == false && dpItem.isEdp == true) && <>
                                            <div className="p-3 border relative mt-3 flex-1" style={{
                                                width: "auto",
                                            }}>
                                                <SignatureCanvas penColor='black'
                                                    ref={signatureRef}
                                                    canvasProps={{ className: 'sigCanvas w-full h-32' }}
                                                />

                                            </div>
                                            <div className="flex py-2">
                                                <input placeholder="ชื่อ-นามสกุล"
                                                    onChange={(e) => {
                                                        setcontactName(e.target.value)
                                                    }}
                                                    className="form-control" value={contactName} />
                                            </div>
                                            <div className="flex py-2">
                                                <button className="flex justify-center items-center text-2xl"
                                                    onClick={() => {
                                                        signatureRef.current.clear();
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>

                                                </button>
                                                <div className="rounded-md">
                                                    <button className={`btn-blue ml-2 ${pageType == "complete" ? "hidden" : ""}`} onClick={uploadSignImage}>
                                                        ยืนยันลายเซ็น
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                (signature && pageType !== "complete") && <div class="flex w-full items-center justify-center bg-grey-lighter my-3">
                                                    <img className="h-32 w-60" src={process.env.REACT_APP_IMAGE + signature} />
                                                </div>
                                            }
                                            {
                                                pageType == "complete" && photosDefault.filter(x => x.photoType == 2).map((item, index) => {
                                                    return <img className="h-32 w-60" src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                                })
                                            }
                                        </>
                                    }
                                    {
                                        pageType == "complete" && <>
                                            {header.signatureByList.find(x => x.shipto == shiptoid.padStart(10, '0') || x.shipto == shiptoid)?.officerName && <div className="flex">
                                                <UserIcon
                                                    className={`h-5 w-5 mr-1`}
                                                />
                                                <span className="text-bold w-16">ชื่อผู้รับ</span>
                                                <div className="flex">

                                                    <span>{header.signatureByList.find(x => x.shipto == shiptoid.padStart(10, '0') || x.shipto == shiptoid)?.officerName}</span>
                                                </div>
                                            </div>}
                                            <div className="flex">
                                                <ClockIcon
                                                    className={`h-5 w-5 mr-1`}
                                                />
                                                <span className="text-bold w-16">เวลาที่ส่ง</span>
                                                <div className="flex">

                                                    <span>{ConvertDate(dpItem.tranLtmswh?.updateDate, 'DD/MM/YYYY HH:mm:ss', 'en', 0)}</span>
                                                </div>
                                            </div>

                                            <div className="flex">
                                                <LocationMarkerIcon
                                                    className={`h-5 w-5 mr-1`}
                                                />
                                                <span className="text-bold w-16">สถานที่ส่ง</span>
                                                <div className="flex">
                                                    <span>{dpItem.tranLtmswh?.lat},{dpItem.tranLtmswh?.lng}</span>
                                                </div>
                                            </div>
                                            {
                                                dpItem.isEdp == true && <div className="flex">
                                                    <LocationMarkerIcon
                                                        className={`h-5 w-5 mr-1`}
                                                    />
                                                    <span className="text-bold w-16">สถานที่รับ</span>
                                                    <div className="flex">
                                                        <span>{dpItem.tranLtmswh?.lat},{dpItem.tranLtmswh?.lng}</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                dpItem.isEdn == true && <div className="flex">
                                                    <LocationMarkerIcon
                                                        className={`h-5 w-5 mr-1`}
                                                    />
                                                    <span className="text-bold w-16">สถานที่รับ</span>
                                                    <div className="flex">
                                                        <span>{dpItem.tranMain?.lat},{dpItem.tranMain?.lng}</span>
                                                    </div>
                                                </div>
                                            }

                                        </>
                                    }

                                    <div className="flex justify-center my-2 gap-x-2">
                                        {
                                            pageType == "delivery" && <button disabled={!state.comments && !(state.isCheckupDriver && state.isCheckupTruck) && !dpItem.isEdn && dpItem.isEdp == true} className="btn-blue ml-2" onClick={() => { finishDelivery() }}>{(dpItem.isEdp == false && dpItem.isEdn == false) ? "บันทึกข้อมูล" : "ยืนยันการรับสินค้า"}</button>
                                        }
                                        {/* {
                                            pageType == "complete" && adminLogin && <button className="btn-blue ml-2" onClick={() => { finishAdmin() }}>บันทึกข้อมูล</button>
                                        } */}
                                        {
                                           (pageType == "complete" && !(shipmentNo?.length == 12)) && <button className="btn-blue ml-2" onClick={() => { genarateReport(shipmentNo, shiptoid) }}>ใบจ่ายสินค้า</button>
                                        }
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>}

                </>
        }


        <Modal isModal={modal}
            onClose={() => {
                setmodal(false);
                setcontactSelected('');
                setstate({ ...state, confirmOTP: '' })
            }}
            onSubmit={() => {
                confirmOTP();
            }}>
            <div className="w-60 flex items-center flex-col gap-2">
                {/* <form onSubmit={confirmOTP}> */}
                <span className="text-base text-gray">ยืนยันรหัส Pin code</span>
                <input
                    maxLength={4}
                    value={state.confirmOTP}
                    onChange={(e) => {
                        setstate({ ...state, confirmOTP: e.target.value })
                    }} required className="form-control w-full mt-2" placeholder="Pincode" type="password" />
                <div className="w-full">
                    {state.messageErrorOTP && <label className="text-red">{state.messageErrorOTP}</label>}
                </div>
                {/* </form> */}
            </div>
        </Modal>
    </>

}


const JobDetailTab5 = ({ webTruckShipment }) => {
    return <Disclosure defaultOpen={true}>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span>Shipment Backhaul</span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    {/* <div className="table-responsive overflow-x-auto">
                        <table class="table-auto border-collapse border border-slate-500 w-full nowrap">
                            <thead>
                                <tr>
                                    <th className="">BH Number</th>
                                    <th>Product Code</th>
                                    <th>ชนิดรถ</th>
                                    <th>ต้นทาง</th>
                                    <th>ปลายทาง</th>
                                    <th>คำสั่งพิเศษ</th>
                                    <th>วันที่รับเศษ</th>
                                    <th>เวลาเริ่มรับ</th>
                                    <th>เวลาสิ้นสุดรับเศษ</th>
                                </tr>
                            </thead>
                            <tbody className="pb-2">
                                <tr>
                                    <td>{webTruckShipment.bhNo}</td>
                                    <td>{webTruckShipment.produceCode}</td>
                                    <td></td>
                                    <td>{webTruckShipment.bhVendorName}</td>
                                    <td>{webTruckShipment.bhDestination}</td>
                                    <td>{webTruckShipment.remark}</td>
                                    <td>{ConvertDate(webTruckShipment.createDate)}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left whitespace-nowrap">BH Number: </span>
                            <span className="text-gray right">&nbsp;{webTruckShipment.bhNo}</span>
                        </div>
                        <div className="flex">
                            <span className="left whitespace-nowrap">คำสั่งพิเศษ: </span>
                            <span className="text-gray right">&nbsp;{webTruckShipment.remark}</span>
                        </div>
                    </div>
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left whitespace-nowrap">Product Code: </span>
                            <span className="text-gray right">&nbsp;{webTruckShipment.produceCode}</span>
                        </div>
                        <div className="flex">
                            <span className="left whitespace-nowrap">วันที่รับเศษ: </span>
                            <span className="text-gray right">&nbsp;{ConvertDate(webTruckShipment.createDate)}</span>
                        </div>
                    </div>
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left whitespace-nowrap">ชนิดรถ: </span>
                            <span className="text-gray right">&nbsp;</span>
                        </div>
                        <div className="flex">
                            <span className="left whitespace-nowrap">เวลาเริ่มรับ: </span>
                            <span className="text-gray right">&nbsp;{webTruckShipment.remark}</span>
                        </div>
                    </div>
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left whitespace-nowrap">ต้นทาง: </span>
                            <span className="text-gray right">&nbsp;</span>
                        </div>
                        <div className="flex">
                            <span className="left whitespace-nowrap">เวลาสิ้นสุดรับเศษ: </span>
                            <span className="text-gray right">&nbsp;</span>
                        </div>
                    </div>
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left whitespace-nowrap">ปลายทาง: </span>
                            <span className="text-gray right">&nbsp;{webTruckShipment.bhDestination}</span>
                        </div>

                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const JobDetailDp = ({ item, lengthDP = 0, orderSuggest = 0, isChangeSuggest, changeSuggest, pageType }) => {
    const userState = useSelector((state) => state.user);
    const [isModal, setisModal] = useState(false);
    useEffect(() => {
        console.log(isChangeSuggest)
    }, []);


    const renderQTY = () => {
        let qty = 0;
        item.dpDetails.map((item, index) => {
            if (item.batchItems) {
                qty += item.batchItems.length
            }

        })
        return parseFloat(qty).toFixed(3);
    }

    const renderQTYDelivery = () => {
        let qty = 0;
        item.dpDetails.map((item, index) => {
            if (item.batchItems) {
                qty += _.sumBy(item.batchItems, 'qty')
            }

        })
        return parseFloat(qty).toFixed(3);
    }

    return (
        <div className="flex w-full mt-3">
            <Modal isModal={isModal} onClose={() => setisModal(false)}>
                <div className="flex items-center flex-col">
                    <div className="flex items-center flex-col gap-10">
                        <Barcode width={3} value={item.dpno} />
                    </div>
                    {/* <img
                    className=""
                    src={`data:image/png;base64, ${barCode.shipmentNoImg}`} />
                <img
                    className=""
                    src={`data:image/png;base64, ${barCode.saleOrgImg}`} /> */}

                </div>
            </Modal>
            <div className="flex flex-col items-center border p-3 border-gray-200 rounded-sm">
                {
                    isChangeSuggest && orderSuggest != 1 && <ArrowCircleUpIcon onClick={() => {
                        changeSuggest(orderSuggest - 1, -1)
                    }} className={`h-4 w-4 cursor-pointer`}></ArrowCircleUpIcon>
                }
                <div >{orderSuggest}</div>
                {
                    isChangeSuggest && orderSuggest != lengthDP && <ArrowCircleDownIcon
                        onClick={() => {
                            changeSuggest(orderSuggest - 1, +1)
                        }}
                        className={`h-4 w-4 cursor-pointer`}></ArrowCircleDownIcon>
                }
            </div>
            <div className="flex flex-1 flex-col border p-3 border-gray-200 rounded-sm">
                <>
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left whitespace-nowrap">เลขที่ใบตั้ง: </span>
                            <span className="text-gray right">&nbsp;{item.dpno}</span>
                        </div>
                        {
                            userState.edpRoleId != 5 && <div>
                                <span className="left">สถานะ: </span>
                                <span className="text-gray right">&nbsp;{userState.edpRoleId != 5 ? item.currentStatus : "-"} <span className="text-red-500 text-base">({ConvertDate(item?.createDate, 'DD/MM/YYYY HH:mm:ss', 'en', 0)})</span></span>
                            </div>
                        }

                    </div>
                    <div className="flex justify-between">
                        <div className="flex">
                            <LocationMarkerIcon
                                className={`h-5 w-5 mr-1`}
                            />
                            <span>{item.whaddress}</span>
                        </div>
                        <div className="flex">
                            <span onClick={() => {
                                setisModal(true);
                            }} className="btn-link">Barcode DP</span>
                        </div>
                    </div>
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left">ช่องจอดที่: </span>
                            <span className="text-gray" dangerouslySetInnerHTML={{ __html: item.parkLotNames }}></span>
                        </div>
                        {userState.edpRoleId != 5 && <div className="">
                            <span className="text-base text-green-300 text-green-700">{userState.edpRoleId != 5 ? item.dpstatus : "ขับรถไปส่งสินค้า"}</span>
                        </div>}

                    </div>
                    {
                        pageType == "pickup" && <div className="text-row flex justify-between">
                            <div className="flex">
                                <span className="left">จำนวน({item.dpDetails.length > 0 && (item.dpDetails[0].qtyUnit == "TON" ? "ROL" : item.dpDetails[0].qtyUnit)}): </span>
                                <span className="text-gray right">&nbsp;{(item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unit == "TON" ? renderQTY() : parseFloat(item.qty).toFixed(3)}</span>
                            </div>
                            <div className="flex">
                                <span className="left">จำนวน({item.dpDetails.length > 0 && item.dpDetails[0].weightUnit == "KG" ? "TON" : item.dpDetails[0].weightUnit}): </span>
                                <span className="text-gray right">&nbsp;{(item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unitton == "KG" ? parseFloat(item.weight * 1000).toFixed(3) : parseFloat(item.weight).toFixed(3)} </span>
                            </div>
                        </div>
                    }

                    {
                        pageType == "delivery" && <div className="text-row flex justify-between">
                            <div className="flex">
                                <span className="left">จำนวน({(item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unit == "TON" ? "ROL" : (item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unit}): </span>
                                <span className="text-gray right">&nbsp;{(item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unit == "TON" ? renderQTY() : renderQTYDelivery()}</span>
                            </div>
                            <div className="flex">
                                <span className="left">น้ำหนัก({(item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unitton}): </span>
                                <span className="text-gray right">&nbsp;{(item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unitton == "KG" ? item.weight * 1000 : item.weight} </span>
                            </div>
                        </div>
                    }


                </>
            </div>
        </div>
    )
}

const JobDetailDpModal = ({ item, lengthDP = 0, orderSuggest = 0, isChangeSuggest, changeSuggest }) => {
    const userState = useSelector((state) => state.user);
    const [isModal, setisModal] = useState(false);
    useEffect(() => {
        console.log(isChangeSuggest)
    }, []);


    const renderQTY = () => {
        let qty = 0;
        item.dpDetails.map((item, index) => {
            if (item.batchItems) {
                qty += item.batchItems.length
            }

        })
        return qty;
    }

    return (
        <div className="flex w-full mt-3">
            <Modal isModal={isModal} onClose={() => setisModal(false)}>
                <div className="flex items-center flex-col">
                    <div className="flex items-center flex-col gap-10">
                        <Barcode width={3} value={item.dpno} />
                    </div>
                    {/* <img
                    className=""
                    src={`data:image/png;base64, ${barCode.shipmentNoImg}`} />
                <img
                    className=""
                    src={`data:image/png;base64, ${barCode.saleOrgImg}`} /> */}

                </div>
            </Modal>
            <div className="flex flex-col items-center border p-3 border-gray-200 rounded-sm">
                {
                    isChangeSuggest && orderSuggest != 1 && <ArrowCircleUpIcon onClick={() => {
                        changeSuggest(orderSuggest - 1, -1)
                    }} className={`h-4 w-4 cursor-pointer`}></ArrowCircleUpIcon>
                }
                <div >{orderSuggest}</div>
                {
                    isChangeSuggest && orderSuggest != lengthDP && <ArrowCircleDownIcon
                        onClick={() => {
                            changeSuggest(orderSuggest - 1, +1)
                        }}
                        className={`h-4 w-4 cursor-pointer`}></ArrowCircleDownIcon>
                }
            </div>
            <div className="flex flex-1 flex-col border p-3 border-gray-200 rounded-sm">
                <>
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left whitespace-nowrap">เลขที่ใบตั้ง: </span>
                            <span className="text-gray right">&nbsp;{item.dpno}</span>
                        </div>
                        {
                            userState.edpRoleId != 5 && <div>
                                <span className="left">สถานะ: </span>
                                <span className="text-gray right">&nbsp;{userState.edpRoleId != 5 ? item.currentStatus : "-"}</span>
                            </div>
                        }

                    </div>
                    <div className="flex justify-between">
                        <div className="flex">
                            <LocationMarkerIcon
                                className={`h-5 w-5 mr-1`}
                            />
                            <span>{item.whaddress}</span>
                        </div>
                        <div className="flex">
                            <span onClick={() => {
                                setisModal(true);
                            }} className="btn-link">Barcode DP</span>
                        </div>
                    </div>
                    <div className="justify-between flex row text-row">
                        <div className="flex">
                            <span className="left">ช่องจอดที่: </span>
                            <span className="text-gray" dangerouslySetInnerHTML={{ __html: item.parkLotNames }}></span>
                        </div>
                        {userState.edpRoleId != 5 && <div className="">
                            <span className="text-base text-green-300 text-green-700">{userState.edpRoleId != 5 ? item.dpstatus : "ขับรถไปส่งสินค้า"}</span>
                        </div>}

                    </div>

                    <div className="text-row flex justify-between">
                        <div className="flex">
                            <span className="left">จำนวน({item.dpDetails.length > 0 && item.dpDetails[0].qtyUnit == "TON" ? "ROL" : item.dpDetails[0].qtyUnit}): </span>
                            <span className="text-gray right">&nbsp;{(item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unit == "TON" ? renderQTY() : item.qty}</span>
                        </div>
                        <div className="flex">
                            <span className="left">จำนวน({item.dpDetails.length > 0 && item.dpDetails[0].weightUnit == "KG" ? "TON" : item.dpDetails[0].weightUnit}): </span>
                            <span className="text-gray right">&nbsp;{(item.dpDetails.length > 0 && item.dpDetails[0].batchItems.length > 0) && item.dpDetails[0].batchItems[0].unitton == "KG" ? item.weight * 1000 : item.weight} </span>
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}
export default Job;